import { createRouter, createWebHistory } from 'vue-router'
import MainView from '../views/Main.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: MainView,
    props: true
  },
  {
    path: '/alustuseks',
    name: 'alustuseks',
    component: MainView,
    props: true
  },
  {
    path: '/hinnakiri',
    name: 'hinnakiri',
    component: MainView,
    props: true
  },
  {
    path: '/kontakt',
    name: 'kontakt',
    component: MainView,
    props: true
  },
  {
    path: '/broneeri',
    name: 'broneeri',
    component: MainView,
    props: true
  },
  {
    path: '/broneeri/:session_type',
    name: 'broneeri_with_type',
    component: MainView,
    props: true
  },
  {
    path: '/privaatsuspoliitika',
    name: 'privaatsuspoliitika',
    component: MainView,
    props: true
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'home',
    component: MainView
  }
//   {
//     path: '/about',
//     name: 'about',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
//   }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

// | PORTREESESSIOON
//       | 60-MIN FOTOSESSIOON
//       | Vähemalt 50 järeltöödeldud pilti
//       | (1-2 inimest)
//       | 200€
//       br
//       | PERE- JA GRUPISESSIOON
//       | 60-MIN FOTOSESSIOON
//       | Vähemalt 50 järeltöödeldud pilti
//       | (3-6 inimest, iga järgnev inimene 10€)
//       | 250€
//       br
//       | MINISESSIOON
//       | 30-MIN FOTOSESSIOON
//       | Vähemalt 25 järeltöödeldud pilti
//       | 150€
//     br
//     p
//       | • STUUDIOS PILDISTADES LISANDUB HINDADELE STUUDIORENT 40€
//       br
//       br
//       | • KODUSESSIOONIDE PUHUL VÕIB LISANDUDA TRANSPORDITASU
//       br
//       br
//       | • TALLINNAST VÄLJASPOOL TOIMUVATEL PILDISTAMISTEL LISANDUB TRANSPORDITASU 0.30€/KM
//       br
//       br
//       | •SUUREMATE GRUPPIDE, PULMADE JA ÜRITUSTE HIND ON KOKKULEPPELINE
//       br
//       br
//       | •ENNE PILDISTAMISE BRONEERIMIST, PALUN TUTVU TINGIMUSTEGA SIIN
