import { createStore } from 'vuex'

export default createStore({
  state: {
    priceitems: {
      mini: {
        type: 'mini',
        title: 'Mini',
        fulltitle: 'Minisessioon',
        description: null,
        duration: 30,
        images: 25,
        people: null,
        price: 180,
        extra_info: [],
        style: {
          backgroundColor: '#b1c6c5'
        }
      },
      portree: {
        type: 'portree',
        title: 'portree',
        fulltitle: 'Portreesessioon',
        description: null,
        duration: 60,
        images: 50,
        people: '1-2',
        price: 240,
        extra_info: [],
        style: {
          backgroundColor: '#fbeeca'
        }
      },
      peregrupp: {
        type: 'peregrupp',
        title: 'Pere / grupp',
        fulltitle: 'Pere- või grupisessioon',
        description: null,
        duration: 60,
        images: 50,
        people: '3-6',
        price: 300,
        extra_info: [
          'Iga järgnev inimene 20 €'
        ],
        style: {
          backgroundColor: '#e5e9c3'
        }
      },
      muu: {
        type: 'muu',
        title: 'Muu',
        fulltitle: 'Muu sessioon',
        description: 'Üritus / Pulm / Tüdrukuteõhtu või hoopis midagi muud',
        duration: null,
        images: null,
        people: null,
        price: null,
        extra_info: [],
        style: {
          backgroundColor: '#dfc59b'
        }
      }
    }
  },
  getters: {
    priceItems (state) {
      return state.priceitems
    },
    getItemByType: (state) => (type) => {
      return state.priceitems[type]
    }
  },
  mutations: {
    increment (state) {
      state.count++
    }
  },
  actions: {
  },
  modules: {
  }
})
